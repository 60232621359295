@mixin cb() {
	display:block;
	&:before,
	&:after{content:"."; display:block; height:0; clear:both; visibility:hidden}
}
@mixin image2x($image, $width:auto, $height:auto) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: #{$width} #{$height};
  }
}
@mixin w1() {
	width:1080px;
	margin: 0 auto;
}
@mixin w2() {
	width:1080px;
	margin: 0 auto;
}

$bdcolor : #ddd;

$xs                 : 320px;
$sm                 : max-width 767px;
$md                 : max-width 991px;
$dl                 : max-width 1199px;
$lg                 : 992px;
$xl                 : 1140px;

$linkColor          : #000;

// board list color
$bdcolor            : #e6e6e6;
$bdtopcolor         : #0a2a70;
$cellhovercolor		: #f8f8f8;
$listthbgcolor		: #f8f8f8;

// board view color
$viewbdcolor    : #e6e6e6;
$viewbdtopcolor : #0a2a70;
$viewheadBgcolor : #f8f8f9;
$viewheadcolor : #333333; 
$cblue : #006abf;
$blue : #006abf;


// input
$formHeight         : 30px;
// button
$btnHeight          : $formHeight;

$color_black        : #000;
$color_deepgray     : #333;
$color_orange       : #e73100;
$color_blue         : #3679C1;
$color_cobalt       : #02728D;
$color_red          : #c10101;
$color_green        : #3A7525;
$pointcolor			: #4b579f;

$bgColor        : #e6e6e6;

// @import "font";
$fontSet	:"malgun gothic","맑은고딕","AppleGothic","돋움","dotum",Helvetica,Arial,sans-serif;
// @import url('https://fonts.googleapis.com/css?family=Noto+Sans');
// @import url('//fonts.googleapis.com/earlyaccess/notosanskr.css');


/*
https://fonts.googleapis.com/earlyaccess/notosanskr.css
Thin(100)
Light(300)
Regular(400)
Medium(500)
Bold(700)
Black(900)
*/

$headingFont        : 'Noto Sans KR', sans-serif;
@mixin fs() {
	font-family:$headingFont;
}
#gnb .nav > li > a,
// #gnb .nav > li > div > ul > li > a,
h1,h2,h3{
	font-family:$headingFont;
	// font-weight:700;
}
@mixin fa() {
	font-family:FontAwesome;
}
@mixin bu4() {
	&:before {
		content: '';
		display:inline-block;
		width:3px;
		height:3px;
		background-color:#000;
		vertical-align:middle;
		margin-right:8px;
		margin-top:-2px;
	}
}

@mixin t1() {
	font-family:'Noto-Regular';
	color:$cblue;	
}
@include breakpoint(max-width 767px){
	img{max-width:100%;}
}