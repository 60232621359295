// font-family:'Noto Sans';
// font-family:LineAwesome;
@import url(/static/font/line-awesome/css/line-awesome.css);
@import "static/bos/scss/_breakpoint.scss";
@import "var";
body::before {display:none}
#content{
	max-width:1200px;
	margin: 0 auto;
}
#visual{
	position:relative;
	.ctrl{
		button{
			z-index:200;
			position:absolute;
			top:155px;
			top:50%;
			margin-top:-25px;
			width:56px;
			height:55px;
			background: url(/static/dsnc/img/main/prev.png) 0 0 no-repeat;
		}
		.mainvisual-prev{left:20px;}
		.mainvisual-next{
			right:20px;
			background: url(/static/dsnc/img/main/next.png) 0 0 no-repeat;
		}
		@include breakpoint($md){
			button{
				width:28px;
				height:28px;
				background-size:100%;
				margin-top:-14px;
			}
			.mainvisual-prev{}
			.mainvisual-next{background-size:100%;}
		}
	}
	.customNav{
		position: absolute;
	    bottom: 0;
	    z-index: 100;
	    text-align: center;
	    width: 100%;
	    color: #000;
				.dot{display:inline;}
				.owl-dot{
					width:22px;height:22px;
					background-color:transparent;
					border:2px solid #000000;
					padding:0;
					margin:0 5px 0 0;
					border-radius:50%;
					&.active{background-color:#000;}
					span{
						position:absolute;width:1px;height:1px;margin:-1px;padding:0;overflow:hidden;clip:rect(0,0,0,0);border:0;
					}
				}
			.mainvisual-stop{
	  	  background-color: transparent;
	  	  font-weight: bold;
	    	vertical-align: middle;
	    }
	}
	.item{
		// height:360px;
		.mobile{display:none;}
		@include breakpoint($md){
			height:auto;
			.mobile{display:block;}
			.pc{display:none;}
		}
	}
}
.top-infor{
	background-color:#f6f6f6;
	overflow:hidden;
	min-height:90px;
	padding-bottom:25px;
	.left{
		float:left;
		padding:25px 0 0 32px;
		font-size:12px;
		@include fs();
		font-weight:400;
		letter-spacing:-0.05em;
		color:#333;
		em{
			color:#0065cb;
		}
		p{
			margin-bottom:6px;
			font-size:15px;
			background: url(/static/dsnc/img/main/main-infor-time.png) 0 center no-repeat;
			padding-left:27px;
			span{
				display:inline-block;
				font-size:16px;
			}
			&.i{
				letter-spacing:-1px;
				background-image:url(/static/dsnc/img/main/main-infor-tel.png);
				margin-bottom:0;
			}
		}
	}
	.right{
		float:right;
		padding-top:20px;
		padding-right:30px;
		a{
			display:block;
			padding:12px 0 12px 86px;
			background: url(/static/dsnc/img/main/chat.png) 0 50% no-repeat;
			font-size:14px;
			@include fs();
			font-weight:600;
			letter-spacing:-1px;
			span{
				font-size:14px;
				// text-decoration:underline;
				margin-left:4px;
				display:inline-block;
				padding-bottom:4px;
				border-bottom:1px solid #a7a7a7;
				letter-spacing:-0.5px;

				display:block;
				margin-left:0;

			}
		}
	}
	@include breakpoint($md){
		display:none;
	}
}
#mainQuick{
	padding-bottom:58px;
	h1{
		padding:45px 0 27px;
		font-size:30px;
		color:#006abf;
		@include fs();
		font-weight:400;		
		margin:0;
		text-align:center;
	}
	// .t{
	// 	display:table;
	// 	width:100%;
	// 	table-layout:fixed;
	// }
	// .s{
	// 	display:table-cell;
	// 	vertical-align:middle;
	// }
	a{
		font-size:14px;
		display:block;
		text-align:center;
		span:before {
			content: '';
			background: url(/static/dsnc/img/main/quick1.png) center 0 no-repeat;
			width:90px;
			height:90px;
			display:block;
			margin: 0 auto;
			margin-bottom:20px;
		}
		span.i2:before{background-image:url(/static/dsnc/img/main/quick2.png);}
		span.i3:before{background-image:url(/static/dsnc/img/main/quick3.png);}
		span.i4:before{background-image:url(/static/dsnc/img/main/quick4.png);}
		span.i5:before{background-image:url(/static/dsnc/img/main/quick5.png);}
		span.i6:before{background-image:url(/static/dsnc/img/main/quick6.png);}
	}
	@include breakpoint($md){
		padding:0 38px 20px;
		h1{
			padding:19px 0 17px;
			font-size:15px;
		}
		.t{
			display:block;
		}
		.s{
			display:block;
		}
		a{
			span:before {
				width:45px;
				height:45px;
				background-size:100%;
			}
		}
		.owl-nav{
			.owl-prev,
			.owl-next{
				position:absolute;
				top:50%;
				margin-top:-8px;
				font-size:0;
				color:transparent;
				&:before {
					font-family:LineAwesome;
					font-size:22px;
					color:#adadad;
				}
			}
			.owl-prev{left:-20px;}
			.owl-next{right:-20px;}
			.owl-prev{&:before {content: ''; }}
			.owl-next{&:before {content: ''; }}
		}
	}
}
a{
	color:#333;
}
#bottom{
	.top{
		overflow:hidden;
	}
}
#sns{
	float:left;
	width:33.33333333333333%;
	min-height:400px;
	background:#dae8f7 url(/static/dsnc/img/main/sns-title.png) 50px 47px no-repeat;
	padding:40px;
	padding-bottom:10px;
	padding-top:110px;
	position:relative;
	button{
		position:absolute;
		top:40px;
		width:52px;
		height:52px;
		background-position:50% 50%;
		background-color:transparent;
		background-repeat:no-repeat;
	}
	.tweeter{
		right:91px;
		background-image:url(/static/dsnc/img/main/sns-t-off.png);
		&.on{
			background-image:url(/static/dsnc/img/main/sns-t-on.png);
		}
	}
	.naver{
		right:35px;
		background-image:url(/static/dsnc/img/main/sns-n-off.png);
		&.on{
			background-image:url(/static/dsnc/img/main/sns-n-on.png);
		}
	}
	
	.s{
		display:none;
		.link{
			border:3px solid #dee0e6;
			background:#fff url(/static/dsnc/img/main/sns-link-bg.png) right bottom no-repeat;
			display:block;
			margin-bottom:20px;
			padding:17px 0;
			overflow:hidden;
			display:table;
			width:100%;
			table-layout:fixed;
			>*{
				display:table-cell;
				vertical-align:middle;
			}
			.date{
				width:90px;
				border-right:1px solid #cacaca;
				text-align:center;
				span{display:block}
				.dd{
					font-size:40px;
					color:#f7941d;
				}
				.yy{
					color:#4f4f4f;
					font-size:15px;
				}
			}
			.txt{
				padding:0 20px;
			}
		}
		
		
	}
	.on+.s{display:block;}
	.go{
		margin-top:20px;
		display:inline-block;
		font-size:36px;
		@include fs();
		font-weight:100;
		line-height:48px;
		padding-right:59px;
		background: url(/static/dsnc/img/main/arr-lg.png) right 50% no-repeat;
	}
	@include breakpoint($md){
		float:none;
		width:auto;
		padding:30px;
		padding-top:88px;
		min-height:0;
		background-position:30px 37px;
		button{
			top:32px;
			width:40px;
			height:40px;
			background-size:auto 40px;
		}
		.link .txt{height:auto;}
		.tweeter{
			right:71px;
			&.on{
			}
		}
		.naver{
			right:30px;
		}
		.go{
			margin-top:10px;
			font-size:26px;
			padding-right:30px;
			background-size:auto 20px;
		}
	}
}
#noticeList{
  float:left;
  width:33.33333333333333%;
  padding:40px 40px 0 50px;
  position:relative;
  .tablist{
    display:table;
    text-align:center;
    margin:0 0 30px -11px;
    li{
    	padding-left:11px;padding-right:11px;
      display:table-cell;
      vertical-align:bottom;
      font-size:18px;
      color:#757575;
      @include fs();
      white-space:nowrap;
      &.on{
        color:#000000;
        font-size:24px;
      }
    }
    li+li{
    	// padding-left:22px;
    }
  }
  .panel{
    display:none;
    // margin-left:19px;
    &.on{
      display:block;
    }
    li{
      // margin-bottom:12px;
      a{
        display:block;
        position:relative;
        overflow:hidden;
        line-height:1;
        padding:15px 0 14px;
      }
      .new{
        display:inline-block;
        vertical-align:middle;
      }
      .t{
        display:block;
        text-overflow:ellipsis;overflow:hidden;white-space:nowrap;
        &:before {
        	content: '- ';
        	display:inline;
        }
      }
      .date{
        position:absolute;
        right:0;
        top:0;
        text-align:right;
        padding-right:10px;
      }
    }
  }
  a.more{
    position:absolute;
    right:40px;
    top:49px;
    line-height:1;
    &:before {
    	content: '';
    	font-family:LineAwesome;
    	font-size:30px;
    	color:#000;
    }
  }
  @include breakpoint($md){
  	float:none;
  	width:auto;
  	padding:33px 40px 30px;
  	.tablist{
  		margin-bottom:15px;
  	  li{
  	  	font-size:15px;
  	    &.on{
  	    	font-size:18px;
  	    }
  	  }
  	  li+li{
  	  }
  	}
  	.panel{
  	  &.on{

  	  }
  	  li{
  	    a{
  	    }
  	    .new{
  	    }
  	    .t{
  	    }
  	    .date{
  	    }
  	  }
  	}
  	a.more{
  		top:34px;
  	}
  }
}
#popupzone{
	.owl-dots{
		position:absolute;
		left:0;
		top:-54px;
		.owl-dot{
			width:22px;height:22px;
			background-color:transparent;
			border:2px solid #000000;
			padding:0;
			margin:0 5px 0 0;
			border-radius:50%;
			&.active{background-color:#000;}
			span{
				position:absolute;width:1px;height:1px;margin:-1px;padding:0;overflow:hidden;clip:rect(0,0,0,0);border:0;
			}
		}
	}
	background-color:#dae8f7;
	width:33.33333333333333%;
	float:right;
	position:relative;
	padding:100px 40px 0 40px;
	height:400px;
	.ctrl{
		position:absolute;
		right:40px;
		top:45px;
		button{
			font-family:LineAwesome;
			font-size:36px;
			color:#000;
			float:left;
			line-height:1;
			// text-indent:-10px;
			padding:0;margin:0;
			background-color:transparent;
			&:before {
				display:inline;
			}
		}
		.pop-prev{&:before {
			content: '';
		}}
		.pop-next{&:before {
			content: '';
		}}
		.pop-pause{
			&:before {
			content: '';
		}}
	}
	p{
		padding-top:28px;
		font-size:16px;
		color:#000;
		padding-left:10px;
	}
	.go{
		@include fs();
		margin-top:18px;
		display:inline-block;
		font-size:32px;
		font-weight:300;
		line-height:48px;
		padding-right:59px;
		padding-left:10px;
		background: url(/static/dsnc/img/main/arr-lg.png) right 50% no-repeat;
		color:#000;
	}
	@include breakpoint($md){
		float:none;
		width:auto;
		padding-left:32px;
		padding-right:32px;
		padding-top:80px;
		padding-bottom:40px;
		height:auto;
		.owl-dots{
		  .owl-dot{
		    span{
		    }
		    &.active span{
		    }
		  }
		}
		.ctrl{
			top:25px;
		  button{
		    &:before {
		    }
		  }
		}
		p{
			padding-top:20px;
		}
		.go{
			margin-top:10px;
			font-size:26px;
			padding-right:30px;
			background-size:auto 20px;
			line-height:1;
		}

	}
}
.service-link{
	overflow:hidden;
	.s{
		margin:0 -10px;
	}
	.set{
		display:table;
		width:100%;
		border-spacing:10px;
	}
	span{
		display:table-cell;height:150px;vertical-align:top;
		background-repeat:no-repeat;
		background-position:50% 0;
	}
	.i1,
	.i2{
		a{
			padding:29px 0 0 40px;
			color:#fff;
			display:block;
			font-weight:100;
			@include fs();
			em{
				font-size:24px;
				display:block;
				margin-bottom:10px;
				font-weight:400;
			}
		}
	}
	.i1{background-image:url(/static/dsnc/img/main/thx-bg.png);width:34.3334%;}
	.i2{background-image:url(/static/dsnc/img/main/acc-bg.png);}
	.i3{background-image:url(/static/dsnc/img/main/faq.png);}
	.i4{background-image:url(/static/dsnc/img/main/map.png);}
	.i3,
	.i4{
		width:16.66%;
		a{
			display:block;
			color:#fff;
			padding-top:95px;
			text-align:center;
			font-size:18px;
			font-weight:400;
		}
	}
	@include breakpoint($md){
		padding-left:10px;
		padding-right:10px;
		.s{
			margin:0;
			padding:10px 0;
		}
		.set{
			display:block;
		}
		span{
			display:block;
			background-size:cover;
		}
		.i1{width:auto;margin-bottom:10px;}
		.i2{width:auto;margin-bottom:10px;}
		.i3{width:auto;float:left;width:50%;border-right:5px solid #fff;}
		.i4{width:auto;float:right;width:50%;border-left:5px solid #fff;}
		.i1,
		.i2{
		  a{
		    em{
		    }
		  }
		}
		.i3,
		.i4{
			background-size:100% 100%;
		  a{
		  	height:75px;
		  }
		}

	}
}
	.b-sh-close{
		display:none;
	}
@include breakpoint($md){
	#footer{margin-top:10px;}
}

@include breakpoint($md){
	.top-infor .right{
		float:none;
		clear:both;
		padding-left:40px;
		padding-top:10px;
	}
	#sns .go{font-size:20px;}
	#popupzone .go{font-size:20px;}
	#noticeList{padding:40px 20px;}
	#noticeList .tablist li{padding-left:4px;padding-right:4px;}
	.service-link .i1 a, .service-link .i2 a{padding-left:20px;}

}
