@charset "UTF-8";
@import url(/static/font/line-awesome/css/line-awesome.css);
/*
https://fonts.googleapis.com/earlyaccess/notosanskr.css
Thin(100)
Light(300)
Regular(400)
Medium(500)
Bold(700)
Black(900)
*/
#gnb .nav > li > a, h1, h2, h3 { font-family: "Noto Sans KR", sans-serif; }

@media (max-width: 767px) { img { max-width: 100%; } }

body::before { display: none; }

#content { max-width: 1200px; margin: 0 auto; }

#visual { position: relative; }

#visual .ctrl button { z-index: 200; position: absolute; top: 155px; top: 50%; margin-top: -25px; width: 56px; height: 55px; background: url(/static/dsnc/img/main/prev.png) 0 0 no-repeat; }

#visual .ctrl .mainvisual-prev { left: 20px; }

#visual .ctrl .mainvisual-next { right: 20px; background: url(/static/dsnc/img/main/next.png) 0 0 no-repeat; }

@media (max-width: 991px) { #visual .ctrl button { width: 28px; height: 28px; background-size: 100%; margin-top: -14px; }
	#visual .ctrl .mainvisual-next { background-size: 100%; } }

#visual .customNav { position: absolute; bottom: 0; z-index: 100; text-align: center; width: 100%; color: #000; }

#visual .customNav .dot { display: inline; }

#visual .customNav .owl-dot { width: 22px; height: 22px; background-color: transparent; border: 2px solid #000000; padding: 0; margin: 0 5px 0 0; border-radius: 50%; }

#visual .customNav .owl-dot.active { background-color: #000; }

#visual .customNav .owl-dot span { position: absolute; width: 1px; height: 1px; margin: -1px; padding: 0; overflow: hidden; clip: rect(0, 0, 0, 0); border: 0; }

#visual .customNav .mainvisual-stop { background-color: transparent; font-weight: bold; vertical-align: middle; }

#visual .item .mobile { display: none; }

@media (max-width: 991px) { #visual .item { height: auto; }
	#visual .item .mobile { display: block; }
	#visual .item .pc { display: none; } }

.top-infor { background-color: #f6f6f6; overflow: hidden; min-height: 90px; padding-bottom: 25px; }

.top-infor .left { float: left; padding: 25px 0 0 32px; font-size: 12px; font-family: "Noto Sans KR", sans-serif; font-weight: 400; letter-spacing: -0.05em; color: #333; }

.top-infor .left em { color: #0065cb; }

.top-infor .left p { margin-bottom: 6px; font-size: 15px; background: url(/static/dsnc/img/main/main-infor-time.png) 0 center no-repeat; padding-left: 27px; }

.top-infor .left p span { display: inline-block; font-size: 16px; }

.top-infor .left p.i { letter-spacing: -1px; background-image: url(/static/dsnc/img/main/main-infor-tel.png); margin-bottom: 0; }

.top-infor .right { float: right; padding-top: 20px; padding-right: 30px; }

.top-infor .right a { display: block; padding: 12px 0 12px 86px; background: url(/static/dsnc/img/main/chat.png) 0 50% no-repeat; font-size: 14px; font-family: "Noto Sans KR", sans-serif; font-weight: 600; letter-spacing: -1px; }

.top-infor .right a span { font-size: 14px; margin-left: 4px; display: inline-block; padding-bottom: 4px; border-bottom: 1px solid #a7a7a7; letter-spacing: -0.5px; display: block; margin-left: 0; }

@media (max-width: 991px) { .top-infor { display: none; } }

#mainQuick { padding-bottom: 58px; }

#mainQuick h1 { padding: 45px 0 27px; font-size: 30px; color: #006abf; font-family: "Noto Sans KR", sans-serif; font-weight: 400; margin: 0; text-align: center; }

#mainQuick a { font-size: 14px; display: block; text-align: center; }

#mainQuick a span:before { content: ''; background: url(/static/dsnc/img/main/quick1.png) center 0 no-repeat; width: 90px; height: 90px; display: block; margin: 0 auto; margin-bottom: 20px; }

#mainQuick a span.i2:before { background-image: url(/static/dsnc/img/main/quick2.png); }

#mainQuick a span.i3:before { background-image: url(/static/dsnc/img/main/quick3.png); }

#mainQuick a span.i4:before { background-image: url(/static/dsnc/img/main/quick4.png); }

#mainQuick a span.i5:before { background-image: url(/static/dsnc/img/main/quick5.png); }

#mainQuick a span.i6:before { background-image: url(/static/dsnc/img/main/quick6.png); }

@media (max-width: 991px) { #mainQuick { padding: 0 38px 20px; }
	#mainQuick h1 { padding: 19px 0 17px; font-size: 15px; }
	#mainQuick .t { display: block; }
	#mainQuick .s { display: block; }
	#mainQuick a span:before { width: 45px; height: 45px; background-size: 100%; }
	#mainQuick .owl-nav .owl-prev, #mainQuick .owl-nav .owl-next { position: absolute; top: 50%; margin-top: -8px; font-size: 0; color: transparent; }
	#mainQuick .owl-nav .owl-prev:before, #mainQuick .owl-nav .owl-next:before { font-family: LineAwesome; font-size: 22px; color: #adadad; }
	#mainQuick .owl-nav .owl-prev { left: -20px; }
	#mainQuick .owl-nav .owl-next { right: -20px; }
	#mainQuick .owl-nav .owl-prev:before { content: ''; }
	#mainQuick .owl-nav .owl-next:before { content: ''; } }

a { color: #333; }

#bottom .top { overflow: hidden; }

#sns { float: left; width: 33.33333333333333%; min-height: 400px; background: #dae8f7 url(/static/dsnc/img/main/sns-title.png) 50px 47px no-repeat; padding: 40px; padding-bottom: 10px; padding-top: 110px; position: relative; }

#sns button { position: absolute; top: 40px; width: 52px; height: 52px; background-position: 50% 50%; background-color: transparent; background-repeat: no-repeat; }

#sns .tweeter { right: 91px; background-image: url(/static/dsnc/img/main/sns-t-off.png); }

#sns .tweeter.on { background-image: url(/static/dsnc/img/main/sns-t-on.png); }

#sns .naver { right: 35px; background-image: url(/static/dsnc/img/main/sns-n-off.png); }

#sns .naver.on { background-image: url(/static/dsnc/img/main/sns-n-on.png); }

#sns .s { display: none; }

#sns .s .link { border: 3px solid #dee0e6; background: #fff url(/static/dsnc/img/main/sns-link-bg.png) right bottom no-repeat; display: block; margin-bottom: 20px; padding: 17px 0; overflow: hidden; display: table; width: 100%; table-layout: fixed; }

#sns .s .link > * { display: table-cell; vertical-align: middle; }

#sns .s .link .date { width: 90px; border-right: 1px solid #cacaca; text-align: center; }

#sns .s .link .date span { display: block; }

#sns .s .link .date .dd { font-size: 40px; color: #f7941d; }

#sns .s .link .date .yy { color: #4f4f4f; font-size: 15px; }

#sns .s .link .txt { padding: 0 20px; }

#sns .on + .s { display: block; }

#sns .go { margin-top: 20px; display: inline-block; font-size: 36px; font-family: "Noto Sans KR", sans-serif; font-weight: 100; line-height: 48px; padding-right: 59px; background: url(/static/dsnc/img/main/arr-lg.png) right 50% no-repeat; }

@media (max-width: 991px) { #sns { float: none; width: auto; padding: 30px; padding-top: 88px; min-height: 0; background-position: 30px 37px; }
	#sns button { top: 32px; width: 40px; height: 40px; background-size: auto 40px; }
	#sns .link .txt { height: auto; }
	#sns .tweeter { right: 71px; }
	#sns .naver { right: 30px; }
	#sns .go { margin-top: 10px; font-size: 26px; padding-right: 30px; background-size: auto 20px; } }

#noticeList { float: left; width: 33.33333333333333%; padding: 40px 40px 0 50px; position: relative; }

#noticeList .tablist { display: table; text-align: center; margin: 0 0 30px -11px; }

#noticeList .tablist li { padding-left: 11px; padding-right: 11px; display: table-cell; vertical-align: bottom; font-size: 18px; color: #757575; font-family: "Noto Sans KR", sans-serif; white-space: nowrap; }

#noticeList .tablist li.on { color: #000000; font-size: 24px; }

#noticeList .panel { display: none; }

#noticeList .panel.on { display: block; }

#noticeList .panel li a { display: block; position: relative; overflow: hidden; line-height: 1; padding: 15px 0 14px; }

#noticeList .panel li .new { display: inline-block; vertical-align: middle; }

#noticeList .panel li .t { display: block; text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }

#noticeList .panel li .t:before { content: '- '; display: inline; }

#noticeList .panel li .date { position: absolute; right: 0; top: 0; text-align: right; padding-right: 10px; }

#noticeList a.more { position: absolute; right: 40px; top: 49px; line-height: 1; }

#noticeList a.more:before { content: ''; font-family: LineAwesome; font-size: 30px; color: #000; }

@media (max-width: 991px) { #noticeList { float: none; width: auto; padding: 33px 40px 30px; }
	#noticeList .tablist { margin-bottom: 15px; }
	#noticeList .tablist li { font-size: 15px; }
	#noticeList .tablist li.on { font-size: 18px; }
	#noticeList a.more { top: 34px; } }

#popupzone { background-color: #dae8f7; width: 33.33333333333333%; float: right; position: relative; padding: 100px 40px 0 40px; height: 400px; }

#popupzone .owl-dots { position: absolute; left: 0; top: -54px; }

#popupzone .owl-dots .owl-dot { width: 22px; height: 22px; background-color: transparent; border: 2px solid #000000; padding: 0; margin: 0 5px 0 0; border-radius: 50%; }

#popupzone .owl-dots .owl-dot.active { background-color: #000; }

#popupzone .owl-dots .owl-dot span { position: absolute; width: 1px; height: 1px; margin: -1px; padding: 0; overflow: hidden; clip: rect(0, 0, 0, 0); border: 0; }

#popupzone .ctrl { position: absolute; right: 40px; top: 45px; }

#popupzone .ctrl button { font-family: LineAwesome; font-size: 36px; color: #000; float: left; line-height: 1; padding: 0; margin: 0; background-color: transparent; }

#popupzone .ctrl button:before { display: inline; }

#popupzone .ctrl .pop-prev:before { content: ''; }

#popupzone .ctrl .pop-next:before { content: ''; }

#popupzone .ctrl .pop-pause:before { content: ''; }

#popupzone p { padding-top: 28px; font-size: 16px; color: #000; padding-left: 10px; }

#popupzone .go { font-family: "Noto Sans KR", sans-serif; margin-top: 18px; display: inline-block; font-size: 32px; font-weight: 300; line-height: 48px; padding-right: 59px; padding-left: 10px; background: url(/static/dsnc/img/main/arr-lg.png) right 50% no-repeat; color: #000; }

@media (max-width: 991px) { #popupzone { float: none; width: auto; padding-left: 32px; padding-right: 32px; padding-top: 80px; padding-bottom: 40px; height: auto; }
	#popupzone .ctrl { top: 25px; }
	#popupzone p { padding-top: 20px; }
	#popupzone .go { margin-top: 10px; font-size: 26px; padding-right: 30px; background-size: auto 20px; line-height: 1; } }

.service-link { overflow: hidden; }

.service-link .s { margin: 0 -10px; }

.service-link .set { display: table; width: 100%; border-spacing: 10px; }

.service-link span { display: table-cell; height: 150px; vertical-align: top; background-repeat: no-repeat; background-position: 50% 0; }

.service-link .i1 a, .service-link .i2 a { padding: 29px 0 0 40px; color: #fff; display: block; font-weight: 100; font-family: "Noto Sans KR", sans-serif; }

.service-link .i1 a em, .service-link .i2 a em { font-size: 24px; display: block; margin-bottom: 10px; font-weight: 400; }

.service-link .i1 { background-image: url(/static/dsnc/img/main/thx-bg.png); width: 34.3334%; }

.service-link .i2 { background-image: url(/static/dsnc/img/main/acc-bg.png); }

.service-link .i3 { background-image: url(/static/dsnc/img/main/faq.png); }

.service-link .i4 { background-image: url(/static/dsnc/img/main/map.png); }

.service-link .i3, .service-link .i4 { width: 16.66%; }

.service-link .i3 a, .service-link .i4 a { display: block; color: #fff; padding-top: 95px; text-align: center; font-size: 18px; font-weight: 400; }

@media (max-width: 991px) { .service-link { padding-left: 10px; padding-right: 10px; }
	.service-link .s { margin: 0; padding: 10px 0; }
	.service-link .set { display: block; }
	.service-link span { display: block; background-size: cover; }
	.service-link .i1 { width: auto; margin-bottom: 10px; }
	.service-link .i2 { width: auto; margin-bottom: 10px; }
	.service-link .i3 { width: auto; float: left; width: 50%; border-right: 5px solid #fff; }
	.service-link .i4 { width: auto; float: right; width: 50%; border-left: 5px solid #fff; }
	.service-link .i3, .service-link .i4 { background-size: 100% 100%; }
	.service-link .i3 a, .service-link .i4 a { height: 75px; } }

.b-sh-close { display: none; }

@media (max-width: 991px) { #footer { margin-top: 10px; } }

@media (max-width: 991px) { .top-infor .right { float: none; clear: both; padding-left: 40px; padding-top: 10px; }
	#sns .go { font-size: 20px; }
	#popupzone .go { font-size: 20px; }
	#noticeList { padding: 40px 20px; }
	#noticeList .tablist li { padding-left: 4px; padding-right: 4px; }
	.service-link .i1 a, .service-link .i2 a { padding-left: 20px; } }
